export const store = [
  { id: 0, background: 'rgba(255, 255, 255, 0.1)' },
  {
    id: 1,
    name: 'Bronze',
    price: '10',
    buyed: false,
    color: '205, 127, 50',
    background: 'rgba(205, 127, 50, 0.2)',
    lvl: 1,
  },
  {
    id: 2,
    name: 'Silver',
    price: '20',
    buyed: false,
    color: '192, 192, 192',
    background: 'rgba(192, 192, 192, 0.1)',
    lvl: 1,
  },
  {
    id: 3,
    name: 'Gold',
    price: '40',
    buyed: false,
    color: '255, 215, 0',
    background: 'rgba(255, 215, 0, 0.2)',
    lvl: 1,
  },
  {
    id: 4,
    name: 'Platinum',
    price: '80',
    buyed: false,
    color: '229, 228, 226',
    background:
      'radial-gradient(290.52% 92.5% at 50% 50%, rgba(229, 228, 226, .4) 0%, rgba(207, 206, 204, .4) 21.79998755455017%, rgba(127, 126, 125, .4) 100%)',
    lvl: 1,
  },
  {
    id: 5,
    name: 'Obsidian',
    price: '160',
    buyed: false,
    color: '142, 0, 213',
    background: 'linear-gradient(180deg, rgba(142, 0, 213, 0.2) 0%, #000 100%)',
    lvl: 2,
  },
  {
    id: 6,
    name: 'Ruby',
    price: '320',
    buyed: false,
    color: '209, 49, 49',
    background: 'linear-gradient(180deg, rgba(255, 78, 95, 0.2) 0%, #000 100%)',
    lvl: 2,
  },
  {
    id: 7,
    name: 'Sapphire',
    price: '640',
    buyed: false,
    color: '0, 144, 255',
    background: 'linear-gradient(180deg, rgba(0, 144, 255, 0.2) 0%, #000 100%)',
    lvl: 2,
  },
  {
    id: 8,
    name: 'Emerald',
    price: '1280',
    buyed: false,
    color: '0, 255, 85',
    background: 'linear-gradient(180deg, rgba(0, 255, 85, .2) 0%, #000 100%)',
    lvl: 2,
  },

  {
    id: 9,
    name: 'Diamond',
    price: '2560',
    buyed: false,
    color: '95, 199, 255',
    background: 'linear-gradient(180deg, rgba(95, 199, 255, 0.3) 0%, rgba(57, 119, 153, 0.3) 100%)',
    lvl: 3,
  },
  {
    id: 10,
    name: 'Mythic',
    price: '5120',
    buyed: false,
    color: '228, 76, 255',
    background: 'linear-gradient(180deg, rgba(185, 49, 209, 0.2)  0%, #000 100%)',
    lvl: 3,
  },
]
