import React, { useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import styles from './FriendsPage.module.css'
import Navbar from '../../components/navigation/Navbar'
import { PageHeader, PageDescription } from '../../components/typography/Typography'
import TextInput from '../../components/forms/TextInput/TextInput'
import { ClipboardIcon, HornIcon, SearchIcon, UsersIcon } from '../../assets/icons'
import PrimaryButton from '../../components/buttons/PrimaryButton/PrimaryButton'
import TelegramAPI from '../../telegram'
import Modal from '../../components/Modal/Modal'
import { useUser } from '../../contexts/userContext'
import API from '../../api/api'
import { serverLink } from '../../data/link-setting'

const tabs = [
  { name: 'invite', title: 'Invite' },
  { name: 'friends', title: 'Your Friends' },
]
const totalFriends = (array) => {
  if (array.length > 0) {
    let totalRefferals = 0
    for (let i = 0; i < array.length; i++) {
      totalRefferals += array[i].countUsers
    }
    return totalRefferals
  }
  return
}

const TabsNavBar = ({ activeTab, setActiveTab, tabs }) => {
  return (
    <div className={styles.tabsNavigation}>
      {tabs.map((item, index) => (
        <div
          className={activeTab === item.name ? styles.activeTabButton : styles.tabButton}
          key={index}
          onClick={() => setActiveTab(item.name)}>
          <div
            className={styles.tabNavigation__text}
            style={activeTab === item.name ? { color: '#fff' } : { color: '#a2acb0' }}>
            {item.title}
          </div>
        </div>
      ))}
    </div>
  )
}

// const friends = [
//   {
//     id: 1,
//     name: 'John Doe',
//     avatar: 'https://via.placeholder.com/150',
//     refferals: 1,
//     rewards: {
//       all: 5000,
//       lines: [
//         { id: 2, friends: 1, reward: 1000 },
//         { id: 3, friends: 1, reward: 1500 },
//         { id: 4, friends: 1, reward: 1500 },
//         { id: 5, friends: 1, reward: 1500 },
//         { id: 6, friends: 1, reward: 1500 },
//         { id: 7, friends: 2, reward: 1500 },
//         { id: 8, friends: 3, reward: 1500 },
//         { id: 9, friends: 3, reward: 1500 },
//         { id: 10, friends: 3, reward: 1500 },
//       ],
//     },
//   },
//   {
//     id: 1,
//     name: 'John Doe',
//     avatar: 'https://via.placeholder.com/150',
//     refferals: 1,
//     rewards: {
//       all: 5000,
//       lines: [
//         { id: 2, friends: 1, reward: 1000 },
//         { id: 3, friends: 1, reward: 1500 },
//         { id: 4, friends: 1, reward: 1500 },
//         { id: 5, friends: 1, reward: 1500 },
//         { id: 6, friends: 1, reward: 1500 },
//         { id: 7, friends: 2, reward: 1500 },
//         { id: 8, friends: 3, reward: 1500 },
//         { id: 9, friends: 3, reward: 1500 },
//         { id: 10, friends: 3, reward: 1500 },
//       ],
//     },
//   },
// ]
const FriendCard = ({ user, onClick, modal = false, currentUser_id, moved = false }) => {
  return (
    <div className={styles.friendCard} onClick={onClick}>
      <div
        className={styles.friendCard__avatar}
        style={
          user.photo_id
            ? {
                background: `url(${serverLink}/user-photo/${user.photo_id})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }
            : {}
        }>
        {!user?.photo_id && user.name.slice(0, 1)}
      </div>
      <div className={styles.friendCard__user}>
        <div className={styles.friendCard__user_header}>
          <div className={styles.friendCard__username}>
            {user.name}{' '}
            {!modal && !moved && (
              <span className={styles.friendCard__userRefferals}>
                <UsersIcon width={12} height={10} /> {totalFriends(user.rewards.levels)}
              </span>
            )}
          </div>
        </div>
        {!moved && (
          <div className={styles.friendCard__user_reward}>
            + {parseFloat((user.reward + user.rewards.total).toFixed(2))} USDT
          </div>
        )}
        {user.first_refer_id !== currentUser_id && <div className={styles.friendCard__moved}>Moved by system</div>}
        {moved && <div className={styles.friendCard__moved} style={{background: `rgba(209, 49, 49, 0.1)`, color: '#D13131'}}>Moved to your level {user.depth}</div>}
      </div>
      {/* <div className={styles.friendCard__icon_container}>
        {modal ? (
          <div className={styles.friendCard__userRefferals}>
            <UsersIcon width={14} height={12} /> {user.refferals}
          </div>
        ) : (
          <SearchIcon />
        )}
      </div> */}

      {!moved && (modal ? (
        <div className={styles.friendCard__userRefferals}>
          <UsersIcon width={12} height={10} /> {totalFriends(user.rewards.levels)}
        </div>
      ) : (
        <div className={styles.friendCard__icon_container}>
          <SearchIcon width={14} height={14} />
        </div>
      ))}
    </div>
  )
}

export default function FriendsPage() {
  const { refferals, user, movedRefferals } = useUser()
  const [selectedId, setSelectedId] = useState(null)

  const [friendData, setFriendData] = useState()
  useEffect(() => {
    setFriendData(refferals.find((item) => item.user_id === selectedId))
  }, [selectedId])

  const [activeTab, setActiveTab] = useState('invite')

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      !friendData &&
        setActiveTab(tabs[Math.min(tabs.findIndex((tab) => tab.name === activeTab) + 1, tabs.length - 1)].name)
    },
    onSwipedRight: () => {
      !friendData && setActiveTab(tabs[Math.max(tabs.findIndex((tab) => tab.name === activeTab) - 1, 0)].name)
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const [copied, setCopied] = useState(false)
  const handleCopyLink = (text) => {
    setCopied(true)
    navigator.clipboard
      .writeText(text)
      .then(() => {
        TelegramAPI.ShowAlert('The referral link has been successfully copied to your clipboard.')
      })
      .catch((err) => {
        TelegramAPI.ShowAlert('Failed to copy the link. Please try again.')
      })
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const handleShare = () => {
    TelegramAPI.Vibrate('heavy')
    TelegramAPI.OpenTelegramLink(`https://t.me/share/url?url=${user?.refferal_link}&text=Register and earn money`)
  }

  return (
    <div {...handlers} className={styles.container}>
      <TabsNavBar activeTab={activeTab} tabs={tabs} setActiveTab={setActiveTab} />
      <div className={styles.tabs}>
        {activeTab === 'invite' && (
          <div className={styles.contentContainer}>
            <PageHeader margin={'50px 0 12px 0'}>Invite friends</PageHeader>
            <PageDescription>
              When you invite a new member to join our community and they purchase a status level higher than yours, you
              will still benefit. However, please be aware that your earnings will be limited to a fraction of what they
              could be.
            </PageDescription>
            <div className={styles.linkContainer}>
              <TextInput
                value={user?.refferal_link}
                inputStyle={copied ? { border: '1.8px solid #fff', pointerEvents: 'none' } : {}}
                labelStyle={copied ? { color: '#fff' } : {}}
                disabled={true}
                onClick={() => handleCopyLink(user?.refferal_link)}
              />
              <div className={styles.buttonsContainer}>
                <PrimaryButton icon={<ClipboardIcon />} onClick={() => handleCopyLink(user?.refferal_link)}>
                  Copy to clipboard
                </PrimaryButton>
                <PrimaryButton onClick={handleShare} icon={<HornIcon width={24} />}>
                  Share
                </PrimaryButton>

                {/* <PrimaryButton
                    icon={<HornIcon height={18} width={18} />}
                    style={{ background: modes[user?.card].shadowColor }}
                    onClick={handleShare}>
                    Invite friend
                  </PrimaryButton>
                  <PrimaryButton
                    icon={<ClipboardIcon width={24} height={24} />}
                    style={{ background: modes[user?.card].shadowColor }}
                    onClick={() => handleCopyLink(user?.referal_link)}
                  /> */}
              </div>
            </div>
          </div>
        )}
        {activeTab === 'friends' && (
          <div className={styles.contentContainer}>
            {/* <PageHeader margin={'50px 0 12px 0'}>Your Friends</PageHeader> */}
            <div className={styles.friendsContainer}>
              {refferals &&
                refferals.map((item, index) => (
                  <FriendCard
                    user={item}
                    key={index}
                    onClick={() => setSelectedId(item.user_id)}
                    currentUser_id={user?.user_id}
                  />
                ))}
              {movedRefferals &&
                movedRefferals.map((item, index) => (
                  <FriendCard user={item} key={index} onClick={() => {}} currentUser_id={user?.user_id} moved={true} />
                ))}
            </div>
          </div>
        )}
      </div>
      {friendData && (
        <Modal title={'Info'} onClose={() => setSelectedId(null)}>
          <FriendCard user={friendData} modal={true} currentUser_id={user?.user_id} />
          <div className={styles.modalSubheader}>Rewards from user</div>
          {friendData && (
            <div className={styles.linesInfo}>
              {friendData?.rewards?.levels &&
                friendData.rewards.levels.map((line, index) => (
                  <div key={index} className={styles.line}>
                    <div className={styles.left}>
                      <div className={styles.lineTitle}>
                        {line.id} <span>line</span>
                      </div>
                      <div className={styles.friensCard}>
                        <UsersIcon width={14} height={12} /> {line.countUsers} friends
                      </div>
                    </div>
                    <div className={styles.right}>+ {parseFloat(line.totalRewards.toFixed(2))} USDT</div>
                  </div>
                ))}
            </div>
          )}
          {/* <PrimaryButton onClick={() => API.settings.openProfile(friendData.user_id)}>Contact</PrimaryButton> */}
        </Modal>
      )}
      <Navbar active={'friends'} />
    </div>
  )
}
