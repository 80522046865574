import React, { useEffect, useRef, useState } from 'react'
import styles from './ShopPage.module.css'
import { PageHeader, PageDescription, PageSubheader } from '../../components/typography/Typography'
import Navbar from '../../components/navigation/Navbar'
import { DiamondIcon, ShieldDiamondIcon, ShieldIcon } from '../../assets/icons'
import Modal from '../../components/Modal/Modal'
import TelegramAPI from '../../telegram'
import PrimaryButton from '../../components/buttons/PrimaryButton/PrimaryButton'
import BalanceCard from '../../components/user/BalanceCard/BalanceCard'
import { useUser } from '../../contexts/userContext'
import { useNavigate } from 'react-router-dom'

const PlanCard = ({ item, onClick }) => {
  return (
    <div
      className={styles.plan}
      onClick={!item.buyed ? onClick : () => {}}
      style={{ background: `radial-gradient(100% 100% at 0% 50%, rgba(${item?.color}, .3) 0%, rgb(0, 0, 0) 100%)` }}>
      <div className={styles.plan_iconContainer}>
        {item.lvl === 1 && <ShieldIcon className={styles.plan_icon} style={{ color: `rgb(${item?.color})` }} />}
        {item.lvl === 2 && <DiamondIcon className={styles.plan_icon} style={{ color: `rgb(${item?.color})` }} />}
        {item.lvl === 3 && (
          <ShieldDiamondIcon
            className={styles.plan_icon}
            style={{ color: `rgb(${item?.color})` }}
            pink={item.id === 10 && true}
          />
        )}
      </div>
      <div className={styles.plan_content}>
        <div className={styles.plan_header} style={{ color: `rgb(${item?.color})` }}>
          {item?.name}
        </div>
        <div className={styles.plan_button} style={{ background: `rgba(${item?.color}, 0.3)` }}>
          {item.buyed ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 6 }}>
              <span>Purchased</span>
              <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 5.12903L4.91304 9L13 1" stroke="white" />
              </svg>
            </div>
          ) : (
            `Purchase for $${item?.price}`
          )}
        </div>
      </div>
    </div>
  )
}

const ModalPlanCard = ({ planData, onClick }) => {
  const { store } = useUser()
  if (planData)
    return (
      <div className={styles.modalPlan}>
        <div
          className={styles.modalPlan_iconContainer}
          style={{ background: `rgba(${store[planData.id - 1].color},0.1)` }}>
          {planData.lvl === 1 && (
            <ShieldIcon className={styles.modalPlan_icon} height={28} style={{ color: `rgb(${planData?.color})` }} />
          )}
          {planData.lvl === 2 && (
            <DiamondIcon className={styles.modalPlan_icon} height={28} style={{ color: `rgb(${planData?.color})` }} />
          )}
          {planData.lvl === 3 && (
            <ShieldDiamondIcon
              className={styles.modalPlan_icon}
              height={48}
              style={{ color: `rgb(${planData?.color})` }}
              pink={planData.id === 10 && true}
            />
          )}
        </div>
        <div className={styles.modalPlan_content}>
          <div className={styles.modalPlan_header} style={{ color: `rgb(${planData?.color})` }}>
            {planData?.name}
          </div>
          <div className={styles.modalPlan_price}>${planData?.price}</div>
        </div>
      </div>
    )
}

export default function ShopPage() {
  const { store, getStore, user, buyStatus } = useUser()
  const [selectedId, setSelectedId] = useState(null)
  const [planData, setPlanData] = useState()

  const navigate = useNavigate()
  useEffect(() => {
    setPlanData(store.find((item) => item.id === selectedId))
  }, [selectedId])

  const handleBuyStatus = (id) => {
    TelegramAPI.Vibrate('heavy')
    setSelectedId(id)
  }

  const modalRef = useRef()

  const handleConfirmBuyingStatus = async (status_id) => {
    TelegramAPI.Vibrate('heavy')
    const response = await buyStatus(status_id)
    TelegramAPI.ShowAlert(response.message)
    modalRef.current.close()
  }

  const handleNavigateToDeposit = async () => {
    TelegramAPI.Vibrate('heavy')
    navigate('/')
  }

  useEffect(() => {
    const fetchShore = async () => {
      getStore()
    }
    fetchShore()
  }, [])

  if (store) {
    return (
      <div className={styles.container}>
        <PageHeader>Store Page</PageHeader>
        <PageSubheader margin={'24px 0 6px 0'}>Maximize Your Earnings Based on Your Status</PageSubheader>
        <PageDescription>
          When you invite a new member to our community and they purchase a status level higher than yours, you will
          still earn rewards. However, your earnings will be limited to a portion of what they could be at a higher
          status level. Upgrade your status to unlock your full earning potential and maximize the benefits of our
          rewards system.
        </PageDescription>

        <div className={styles.shopContainer}>
          {store &&
            store.map((item, index) => <PlanCard item={item} key={index} onClick={() => handleBuyStatus(item.id)} />)}
        </div>

        {planData && (
          <Modal ref={modalRef} onClose={() => setSelectedId(null)} title={'Complete order'}>
            <ModalPlanCard planData={planData} />
            <div className={styles.modalBottomContainer}>
              <BalanceCard />
              <div className={styles.modalButtonContainer}>
                {user.balance >= planData.price ? (
                  <PrimaryButton onClick={() => handleConfirmBuyingStatus(planData.id)}>
                    Buy for ${planData.price}
                  </PrimaryButton>
                ) : (
                  <PrimaryButton onClick={handleNavigateToDeposit}>Deposit</PrimaryButton>
                )}
              </div>
            </div>
          </Modal>
        )}
        <Navbar active={'shop'} />
      </div>
    )
  }
}
