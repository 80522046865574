class TelegramAPI {
  /**
   * MainButton control object
   */
  static MainButton = {
    /**
     * Sets the text of the main button.
     *
     * @param {string} text - The text to display on the main button.
     */
    setText(text) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.setText(text)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Sets the main button press event handler.
     *
     * @param {function} callback - The callback function to call when the main button is pressed.
     */
    onClick(callback) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.onEvent('mainButtonClicked', callback)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Removes the main button press event handler.
     *
     * @param {function} callback - The callback function to remove from the main button press event.
     */
    offClick(callback) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.offEvent('mainButtonClicked', callback)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Makes the main button visible.
     */
    show() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.show()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Hides the main button.
     */
    hide() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.hide()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Enables the main button.
     */
    enable() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.enable()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Disables the main button.
     */
    disable() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.disable()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Shows a loading indicator on the main button.
     *
     * @param {boolean} leaveActive - If true, the button remains enabled while showing the loading indicator.
     */
    showProgress(leaveActive = false) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.showProgress(leaveActive)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Hides the loading indicator on the main button.
     */
    hideProgress() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.hideProgress()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },

    /**
     * Sets the parameters of the main button.
     *
     * @param {Object} params - The parameters to configure the main button.
     * @param {string} [params.text] - The text to display on the main button.
     * @param {string} [params.color] - The color of the main button.
     * @param {string} [params.textColor] - The text color of the main button.
     * @param {boolean} [params.isActive] - If true, the button is enabled.
     * @param {boolean} [params.isVisible] - If true, the button is visible.
     */
    setParams(params) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.MainButton) {
          window.Telegram.WebApp.MainButton.setParams(params)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },
  }
  /**
   * Triggers Telegram haptic feedback with the given option.
   *
   * @param {'light' | 'medium' | 'heavy' | 'rigid' | 'soft'} [option='heavy'] - The haptic feedback option to use.
   */
  static Vibrate(option = 'heavy') {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.HapticFeedback.impactOccurred(option)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Sends data to the bot and closes the Mini App.
   *
   * @param {Object} data - The data to send to the bot. The data length should not exceed 4096 bytes.
   */
  static sendData(data) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        const jsonData = JSON.stringify(data)
        if (jsonData.length <= 4096) {
          window.Telegram.WebApp.sendData(jsonData)
        } else {
          throw new Error('Data length exceeds 4096 bytes')
        }
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error sending data:', error)
    }
  }

  /**
   * Closes the Mini App.
   */
  static close() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.close()
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error sending data:', error)
    }
  }

  /**
   * Shows a message in a simple alert with a 'Close' button.
   *
   * @param {string} message - The message to display in the alert.
   * @param {function} [callback] - Optional callback function to call when the alert is closed.
   */
  static ShowAlert(message, callback) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.showAlert(message, callback)
      } else {
        alert(message)
        throw new Error('Telegram WebApp is not available')

      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Shows a confirmation dialog with the specified message.
   *
   * @param {string} message - The message to display in the confirmation dialog.
   * @param {function} callback - Callback function to call with the user's response.
   */
  static ShowConfirm(message, callback) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.showConfirm(message, callback)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Shows a native popup described by the params argument.
   *
   * @param {Object} params - The parameters to configure the popup.
   * @param {string} [params.title] - The text to be displayed in the popup title, 0-64 characters.
   * @param {string} params.message - The message to be displayed in the body of the popup, 1-256 characters.
   * @param {Array} [params.buttons] - List of buttons to be displayed in the popup, 1-3 buttons.
   * @param {function} [callback] - Optional callback function to call when the popup is closed.
   *                                The field id of the pressed button will be passed as the first argument.
   */
  static ShowPopup(params, callback) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.showPopup(params, callback)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Expand TeLegram web app to full size
   */
  static expand() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.expand()
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Shows the back button in the header of the Mini App.
   */
  static ShowBackButton() {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.BackButton) {
        window.Telegram.WebApp.BackButton.show()
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Hides the back button in the header of the Mini App.
   */
  static HideBackButton() {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.BackButton) {
        window.Telegram.WebApp.BackButton.hide()
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Sets the event handler for the back button press event.
   *
   * @param {function} callback - Callback function to call when the back button is pressed.
   */
  static OnBackButtonClick(callback) {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.BackButton) {
        window.Telegram.WebApp.BackButton.onClick(callback)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Removes the event handler for the back button press event.
   *
   * @param {function} callback - Callback function to remove from the back button press event.
   */
  static OffBackButtonClick(callback) {
    try {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.BackButton) {
        window.Telegram.WebApp.BackButton.offClick(callback)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  /**
   * Sets the app header color.
   *
   * @param {string} color - The color to set for the app header in the #RRGGBB format or bg_color, secondary_bg_color keywords.
   */
  static SetHeaderColor(color) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.setHeaderColor(color)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error setting header color:', error)
    }
  }

  /**
   * Sets the app background color.
   *
   * @param {string} color - The color to set for the app background in the #RRGGBB format or bg_color, secondary_bg_color keywords.
   */
  static SetBackgroundColor(color) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.setBackgroundColor(color)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error setting background color:', error)
    }
  }

  /**
   * Opens a link in an external browser.
   *
   * @param {string} url - The URL to open.
   * @param {Object} [options] - Optional settings for opening the link.
   * @param {boolean} [options.try_instant_view] - If true, tries to open the link in Instant View mode if possible.
   */
  static OpenLink(url, options) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openLink(url, options)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error with openning Link:', error)
    }
  }

  /**
   * Opens a telegram link inside the Telegram app.
   *
   * @param {string} url - The telegram URL to open.
   */
  static OpenTelegramLink(url) {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.openTelegramLink(url)
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error with openening TG link:', error)
    }
  }

  /**
   * Gets the initData string.
   * @returns {string} The raw data string transferred to the Mini App.
   */
  static getInitData() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.initData
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error getting initData:', error)
    }
  }

  /**
   * Gets the initDataUnsafe object.
   * @returns {Object} The input data object transferred to the Mini App.
   */
  static getInitDataUnsafe() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.initDataUnsafe
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error getting initDataUnsafe:', error)
    }
  }

  static getWebAppUser() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.WebAppUser
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error getting WebAppUser:', error)
    }
  }

  static getWebAppChat() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.WebAppChat
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error getting WebAppChat:', error)
    }
  }

  /**
   * Gets the Bot API version available in the user's Telegram app.
   * @returns {string} The version of the Bot API.
   */
  static getVersion() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.version
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error getting version:', error)
    }
  }

  /**
   * Gets the platform name of the user's Telegram app.
   * @returns {string} The platform name.
   */
  static getPlatform() {
    try {
      if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.platform
      } else {
        throw new Error('Telegram WebApp is not available')
      }
    } catch (error) {
      console.error('Error getting platform:', error)
    }
  }

  static SettingsButton = {
    /**
     * Shows the Settings item in the context menu.
     */
    show() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.SettingsButton) {
          window.Telegram.WebApp.SettingsButton.show()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error showing Settings button:', error)
      }
    },

    /**
     * Hides the Settings item in the context menu.
     */
    hide() {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.SettingsButton) {
          window.Telegram.WebApp.SettingsButton.hide()
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error hiding Settings button:', error)
      }
    },

    /**
     * Sets the event handler for the Settings button click event.
     *
     * @param {function} callback - The callback function to call when the Settings button is clicked.
     */
    onClick(callback) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.SettingsButton) {
          window.Telegram.WebApp.onEvent('settingsButtonClicked', callback)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error setting Settings button click handler:', error)
      }
    },

    /**
     * Removes the event handler for the Settings button click event.
     *
     * @param {function} callback - The callback function to remove from the Settings button click event.
     */
    offClick(callback) {
      try {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.SettingsButton) {
          window.Telegram.WebApp.offEvent('settingsButtonClicked', callback)
        } else {
          throw new Error('Telegram WebApp is not available')
        }
      } catch (error) {
        console.error('Error removing Settings button click handler:', error)
      }
    },
  }
}

export default TelegramAPI