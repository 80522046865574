import React from 'react'
import styles from './PrimaryButton.module.css'
import TelegramAPI from '../../../telegram'

export default function PrimaryButton({ children, onClick, icon, href, style }) {
  if (href)
    return (
      <a href={href} className={styles.button} onClick={() => TelegramAPI.Vibrate('heavy')}>
        {icon && icon}
        <div>{children}</div>
      </a>
    )
  return (
    <div className={styles.button} onClick={onClick} style={style}>
      {icon && icon}
      <div>{children}</div>
    </div>
  )
}
