import axios from 'axios'
import TelegramAPI from '../telegram'
import { serverLink } from '../data/link-setting'

const initData = TelegramAPI.getInitData() // Получаем initData
const user = TelegramAPI.getInitDataUnsafe()
const user_id = user?.user?.id

// Создаем axios instance с заголовком Authorization
const axiosInstance = axios.create({
  headers: {
    'ngrok-skip-browser-warning': 'true',
    Authorization: `Bearer ${initData}`,
  },
})

class UsersAPI {
  static async getUser() {
    try {
      const response = await axiosInstance.get(`${serverLink}/api/user/${user_id}`)
      return response.data
    } catch (e) {
      console.error('Error with get users.')
    }
  }

  static async getReffertals() {
    try {
      const response = await axiosInstance.get(`${serverLink}/api/user/${user_id}/refferals`)
      return response.data
    } catch (e) {
      console.error('Error with get refferals')
    }
  }

  static async withdraw(amount, wallet, pin) {
    try {
      const response = await axiosInstance.post(`${serverLink}/api/user/${user_id}/withdraw`, { amount, wallet, pin })
      return response.data
    } catch (e) {
      console.error('Error with withdraw: ')
    }
  }

  static async deposit(amount, address, transaction_id, invoice_wallet) {
    try {
      const response = await axiosInstance.post(`${serverLink}/api/user/${user_id}/deposit`, {
        amount,
        address,
        transaction_id,
        invoice_wallet,
      })
      return response.data
    } catch (e) {
      console.error('Error with withdraw: ')
    }
  }

  // static async deposit(user_id, amount, address, transaction_id, invoice_wallet) {
  //   try {
  //     const response = await axiosInstance.post(`${serverLink}/user/${user_id}/deposit`, { amount, address, transaction_id, invoice_wallet })
  //     return response.data
  //   } catch (e) {
  //     console.error('Error with withdraw: ')
  //   }
  // }

  static async setPin(pin) {
    try {
      const response = await axiosInstance.post(`${serverLink}/api/user/setPin`, { user_id, pin })
      return response.data
    } catch (e) {
      console.error('Error with withdraw: ')
    }
  }
}

class ShopAPI {
  static async getStore() {
    try {
      const response = await axiosInstance.get(`${serverLink}/api/shop/${user_id}`)
      return response.data
    } catch (e) {
      console.error('Error with server')
    }
  }

  static async buyStatus(status_id) {
    try {
      const response = await axiosInstance.post(`${serverLink}/api/shop/buy`, { user_id, status_id })
      return response.data
    } catch (e) {
      console.error('Error with server')
    }
  }
}

class SettingsAPI {
  static async openProfile() {
    TelegramAPI.Vibrate('heavy')
    TelegramAPI.OpenLink(`${serverLink}/api/redirect?user_id=${user_id}`)
  }

  static async getWalletUSDTbalance(owner_address) {
    const net = 'main'
    const jetton_master_address =
      net === 'main'
        ? 'EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs'
        : 'kQD0GKBM8ZbryVk2aESmzfU6b9b_8era_IkvBSELujFZPsyy'
    try {
      const response = await axios.get(
        `https://${
          net === 'test' ? 'testnet.' : ''
        }toncenter.com/api/v3/jetton/wallets?owner_address=${owner_address}&jetton_address=${jetton_master_address}&limit=10&offset=0`
      )
      return response.data
    } catch (e) {
      console.log('Error with server')
    }
  }
}

class ActivityAPI {
  static async getLastTransactions() {
    try {
      const response = await axiosInstance.get(`${serverLink}/api/activity`)
      return response.data
    } catch (e) {
      console.error('Error with server')
      
    }
  }


}

class API {
  static user = UsersAPI
  static shop = ShopAPI
  static settings = SettingsAPI
  static activity = ActivityAPI
}

export default API


// https://toncenter.com/api/v3/jetton/wallets?owner_address=UQD_gHpOYcvG_NjszMXbtYhnpJXT0E5i8S4lqLrRVONc0kse&jetton_address=kQD0GKBM8ZbryVk2aESmzfU6b9b_8era_IkvBSELujFZPsyy&limit=10&offset=0