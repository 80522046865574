import { useContext, useEffect } from 'react';
import { CHAIN, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { Address } from '@ton/core';
import { TonClientContext } from '../contexts/ton-client-context';
import { JettonMaster } from '@ton/ton';

export const useTonConnect = () => {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const { tonClient } = useContext(TonClientContext);

  const walletAddress = wallet?.account?.address ? Address.parse(wallet.account.address) : undefined;
  const network = wallet?.account?.chain ?? null;

  useEffect(() => {
    const disconnectIfTestnet = async () => {
      // Если сеть тестовая, отключаем кошелек
      if (network === CHAIN.TESTNET) {
        console.warn('Testnet detected, disconnecting...');
        await tonConnectUI.disconnect();
        return; // Прекращаем выполнение дальнейшего кода
      }

      // Если сеть корректная и есть подключенный кошелек, выводим баланс
      // if (tonClient && walletAddress) {
      //   const getWalletBalance = async () => {
      //     try {
      //       const jettonMaster = tonClient.open(JettonMaster.create(walletAddress));
      //       const walletData = await jettonMaster.getWalletData(tonClient);
      //       console.log('Wallet Balance:', walletData.balance.toString());
      //     } catch (error) {
      //       console.error('Failed to fetch wallet balance:', error);
      //     }
      //   };

      //   await getWalletBalance();
      // }
    };

    disconnectIfTestnet(); // вызываем асинхронную функцию

  }, [network, tonClient, walletAddress, tonConnectUI]);

  return {
    sender: {
      send: async (args) => {
        await tonConnectUI.sendTransaction({
          messages: [
            {
              address: args.to.toString(),
              amount: args.value.toString(),
              payload: args.body?.toBoc()?.toString('base64'),
            },
          ],
          validUntil: Date.now() + 5 * 60 * 1000, // 5 minutes for user to approve
        });
      },
      address: walletAddress,
    },

    connected: !!wallet?.account?.address,
    walletAddress: walletAddress ?? null,
    network: network,
    tonConnectUI,
    tonClient
  };
};