export function formatDateWithoutYear(isoDate) {
    const options = {
        month: 'short', 
        day: 'numeric', 
        hour: '2-digit',
        minute: '2-digit', 
    };

    const date = new Date(isoDate);
    return date.toLocaleString('en-US', options);
}