import { Address } from '@ton/core';

// export const USDT_MASTER_ADDRESS = Address.parse('kQD0GKBM8ZbryVk2aESmzfU6b9b_8era_IkvBSELujFZPsyy');
// export const INVOICE_WALLET_ADDRESS = Address.parse('0QAUyO90GG5zfe00NhIyqrxP8KlkzYRXfav6f4ToAKPFq38c');


// import { Address } from '@ton/core';

export const USDT_MASTER_ADDRESS = Address.parse('EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs');
export const INVOICE_WALLET_ADDRESS = Address.parse('UQD_gHpOYcvG_NjszMXbtYhnpJXT0E5i8S4lqLrRVONc0kse');

// UQDThk9p-i1tYFJeDPI-dIF8uBxOeJbIznYEc5SZJZnM7oj1