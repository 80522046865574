import { createContext, useState } from 'react'
import { CHAIN } from '@tonconnect/ui-react'
import { TonClient } from '@ton/ton'
import { useTonConnect } from '../hooks/useTonConnect'
import { useAsyncInitialize } from '../hooks/useAsyncInitialize'

// Начальный контекст
const initialContext = {
  tonClient: undefined,
}

export const TonClientContext = createContext(initialContext)

export const TonClientProvider = ({ children }) => {
  const { network } = useTonConnect()
  const [client, setClient] = useState()

  useAsyncInitialize(async () => {
    if (!network) return

    // const tonClient = new TonClient({ endpoint: 'https://testnet.toncenter.com/api/v2/jsonRPC' })
    const tonClient = new TonClient({ endpoint: 'https://toncenter.com/api/v2/jsonRPC' })
    setClient(tonClient)
  }, [network])

  return <TonClientContext.Provider value={{ tonClient: client }}>{children}</TonClientContext.Provider>
}
