import React from 'react';
import styles from './TextInput.module.css';

export default function TextInput({ value, setValue, inputStyle, labelStyle, disabled, onClick, label, type = 'text' }) {
  
  // Функция для фильтрации ввода в зависимости от типа
  const handleKeyPress = (e) => {
    const { key } = e;
    if (type === 'int') {
      // Разрешаем только цифры
      if (!/^\d$/.test(key)) {
        e.preventDefault();
      }
    } else if (type === 'float') {
      // Разрешаем цифры и одну точку
      if (!/^\d$/.test(key) && key !== '.') {
        e.preventDefault();
      }
      // Не позволяем ввести более одной точки
      if (key === '.' && value.includes('.')) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className={styles.container} onClick={onClick}>
      <label className={styles.label} style={labelStyle}>{label}</label>
      <input 
        value={value} 
        className={styles.input} 
        style={inputStyle} 
        disabled={disabled} 
        onChange={setValue} 
        onKeyPress={handleKeyPress}
      />
    </div>
  );
}