import styles from './Activity.module.css'
import Navbar from '../../components/navigation/Navbar'
import activity from '../../assets/view.gif'
import { useEffect, useState } from 'react'
import API from '../../api/api'
import { formatDateWithoutYear } from '../../helpers'
import { store } from '../../data'
import { serverLink } from '../../data/link-setting'
import { useUser } from '../../contexts/userContext'

const MoneyIcon = () => (
  <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0ZM7.48837 3.4186C7.48837 3.14889 7.26971 2.93023 7 2.93023C6.73029 2.93023 6.51163 3.14889 6.51163 3.4186V3.94886C6.10836 4.01911 5.73874 4.17549 5.4397 4.40103C5.02302 4.71531 4.72093 5.18599 4.72093 5.74595C4.72093 6.23944 4.89213 6.69877 5.33931 7.00671C5.74699 7.28742 6.31608 7.39194 7 7.39194C7.62245 7.39194 7.991 7.48798 8.19423 7.62342C8.3552 7.73074 8.46512 7.89724 8.46512 8.25414C8.46512 8.55784 8.34231 8.74199 8.1258 8.87496C7.87985 9.02596 7.49365 9.11628 7 9.11628C6.54718 9.11628 6.1585 8.98859 5.89641 8.80737C5.63234 8.62485 5.53488 8.42123 5.53488 8.25414C5.53488 7.98443 5.31623 7.76577 5.04651 7.76577C4.77679 7.76577 4.55814 7.98443 4.55814 8.25414C4.55814 8.83296 4.89799 9.3046 5.34095 9.61084C5.66782 9.83679 6.07196 9.99099 6.51163 10.0569V10.5814C6.51163 10.8511 6.73029 11.0698 7 11.0698C7.26971 11.0698 7.48837 10.8511 7.48837 10.5814V10.0674C7.90479 10.0217 8.30447 9.91141 8.63683 9.70734C9.12735 9.40618 9.44186 8.91507 9.44186 8.25414C9.44186 7.64634 9.2262 7.13753 8.736 6.81077C8.28807 6.51208 7.67988 6.41519 7 6.41519C6.38159 6.41519 6.05536 6.31387 5.89328 6.20226C5.77066 6.11781 5.69767 5.99832 5.69767 5.74595C5.69767 5.56 5.79644 5.35538 6.02788 5.18084C6.2595 5.00611 6.60162 4.88372 7 4.88372C7.39838 4.88372 7.7405 5.00611 7.97212 5.18084C8.20354 5.35538 8.30233 5.56 8.30233 5.74595C8.30233 6.01564 8.52099 6.2343 8.7907 6.2343C9.06041 6.2343 9.27907 6.01564 9.27907 5.74595C9.27907 5.18599 8.977 4.71531 8.56032 4.40103C8.26124 4.17549 7.89164 4.01911 7.48837 3.94886V3.4186Z"
      fill="currentColor"
    />
  </svg>
)

export default function Activity() {
  const {user} = useUser()
  const [activityT, setActivityT] = useState([])
  const handleGetActivity = async () => {
    const response = await API.activity.getLastTransactions()
    if (response.success) {
      setActivityT(response.data)
    }
  }
  useEffect(() => {
    handleGetActivity()
  }, [])

  if (!user.user_id) return
  return (
    <div className={styles.container}>
      <div className={styles.header}>
      <img src={activity} alt="" width={150} height={150} loading="lazy" />
      <div className={styles.pageTitle}>Activity</div>
      </div>
      <div className={styles.transactionsSubheader}>LAST 100 TRANSACTIONS</div>
      <div>
        {activityT &&
          activityT.map((item, index) => {
            if (item.type === 'purchase')
              return (
                <div key={index} className={styles.transactionCard}>
                  <div
                    className={styles.avatar}
                    style={
                      item?.photo_id
                        ? {
                            background: `url(${serverLink}/user-photo/${item.photo_id})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }
                        : {}
                    }>
                    {!item?.photo_id && item?.name.slice(0, 1)}
                  </div>
                  <div className={styles.body}>
                    <div className={styles.title}>
                      {item.name} purchased{' '}
                      <span
                        className={styles.highlightStatus}
                        style={{ color: `rgb(${store[parseInt(item.additional)].color})` }}>
                        {store[parseInt(item.additional)].name}
                      </span>{' '}
                      for{' '}
                      <span className={styles.highlightWithdraw} style={{color: '#fff', background: `rgba(255,255,255,0.15)`}}>
                        <MoneyIcon /> <div>{item.amount} <span className={styles.usdt}>USDT</span></div>
                      </span>
                    </div>
                    <div className={styles.date}>{formatDateWithoutYear(item.transaction_date)}</div>
                  </div>
                </div>
              )
            if (item.type === 'withdrawal')
              return (
                <div key={index} className={styles.transactionCard}>
                  <div
                    className={styles.avatar}
                    style={
                      item?.photo_id
                        ? {
                            background: `url(${serverLink}/user-photo/${item.photo_id})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                          }
                        : {}
                    }>
                    {!item?.photo_id && item?.name.slice(0, 1)}
                  </div>
                  <div className={styles.body}>
                    <div className={styles.title}>
                      {item.name} withdrew{' '}
                      <span className={styles.highlightWithdraw}>
                        <MoneyIcon /> <div>{item.amount} <span className={styles.usdt}>USDT</span></div>
                      </span>
                    </div>
                    <div className={styles.date}>{formatDateWithoutYear(item.transaction_date)}</div>
                  </div>
                </div>
              )
          })}
      </div>
      <Navbar active="activity" />
    </div>
  )
}

// • Noah purchased Sapphire for $15 USDT
// November 5 at 5:51 AM
