import React from 'react'
import styles from './Typography.module.css'

export function PageSubheader({ children, margin, padding }) {
  return (
    <div className={styles.page_subHeader} style={{ margin: margin, padding: padding }}>
      {children}
    </div>
  )
}

export function PageHeader({ children, margin, padding }) {
  return (
    <div className={styles.page_header} style={{ margin: margin, padding: padding }}>
      {children}
    </div>
  )
}

export function PageDescription({ children, margin, padding }) {
  return (
    <div className={styles.page_description} style={{ margin: margin, padding: padding }}>
      {children}
    </div>
  )
}
