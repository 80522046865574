import React, { createContext, useContext, useEffect, useState } from 'react'
import API from '../api/api'
import TelegramAPI from '../telegram'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [isVideoPlaying, setIsVideoPlaying] = useState(true)
  const [userId, setUserId] = useState()
  const [store, setStore] = useState([])
  const [refferals, setRefferals] = useState([])
  const [movedRefferals, setMovedRefferals] = useState([])
  const [error, setError] = useState(false)

  const updateUserInfo = async () => {
    const initData = await TelegramAPI.getInitDataUnsafe()

    TelegramAPI.getWebAppChat()
    const user_id = initData?.user?.id 
    const response = await API.user.getUser(user_id)
    if (response) {
      setUser(response)
    } else {
      setError(true)
    }
  }

  const getRefferals = async () => {
    try {
      const result = await API.user.getReffertals()
      setRefferals(result.firstLevelUsers)
      setMovedRefferals(result.movedRefferals)
      return result
    } catch (e) {
      console.error(e)
    }
  }

  const getStore = async () => {
    try {
      const result = await API.shop.getStore()
      setStore(result.data)
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  const buyStatus = async (status_id) => {
    try {
      const result = await API.shop.buyStatus( status_id)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const withdraw = async (amount, wallet, pin) => {
    try {
      const result = await API.user.withdraw( amount, wallet, pin)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const deposit = async (amount, address, transaction_id, invoice_wallet) => {
    try {
      const result = await API.user.deposit( amount, address, transaction_id, invoice_wallet)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  const updatePinBack = async (pin) => {
    try {
      const result = await API.user.setPin( pin)
      updateUserInfo()
      return result
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    updateUserInfo()
  }, [])

  useEffect(() => {
    if (user) {
      getStore()
      getRefferals()
    }
  }, [user])

  return (
    <UserContext.Provider
      value={{
        user,
        updateUserInfo,
        getRefferals,
        movedRefferals,
        getStore,
        store,
        refferals,
        buyStatus,
        error,
        withdraw,
        deposit,
        updatePinBack,
        isVideoPlaying,
        setIsVideoPlaying
      }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  return useContext(UserContext)
}
