import React, { useEffect, useState, useRef, useCallback } from 'react'
import { TonConnectButton, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react'

import TextInput from '../../../components/forms/TextInput/TextInput'
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton'
import BalanceCard from '../../../components/user/BalanceCard/BalanceCard'
import { PageHeader } from '../../../components/typography/Typography'
import Modal from '../../../components/Modal/Modal'

import styles from './DepositModal.module.css'

import { useNavigate } from 'react-router-dom'
import { Address, JettonMaster } from '@ton/ton'
import { useTonConnectModal } from '@tonconnect/ui-react'

import TelegramAPI from '../../../telegram'
import { useUser } from '../../../contexts/userContext'
import { useGenerateId } from '../../../hooks/useGenerateId'
import { JETTON_TRANSFER_GAS_FEES } from '../../../constants/fees.constants'
import { INVOICE_WALLET_ADDRESS, USDT_MASTER_ADDRESS } from '../../../constants/common-constants'
import { JettonWallet } from '../../../wrappers/JettonWallet'
import { useTonConnect } from '../../../hooks/useTonConnect'
import API from '../../../api/api'

export default function DepositModal({ handleClose }) {
  const modalRef = useRef()
  const [amount, setAmount] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')

  const handleCloseRef = () => {
    modalRef.current.close()
  }

  //   TON TRANSACTION

  const { deposit, updateUserInfo } = useUser()
  const navigate = useNavigate()
  const { open } = useTonConnectModal()
  const orderId = useGenerateId()
  const { sender, walletAddress, tonClient } = useTonConnect()

  const handleCompletePayment = useCallback(
    async (amount) => {
      if (amount < 1) {
        return
      }
      try {
        if (!tonClient || !walletAddress) return

        const jettonMaster = tonClient.open(JettonMaster.create(USDT_MASTER_ADDRESS))
        const usersUsdtAddress = await jettonMaster.getWalletAddress(walletAddress)


        const jettonWallet = tonClient.open(JettonWallet.createFromAddress(usersUsdtAddress))
        // const walletData = await jettonWallet.getWalletData(tonClient)
        // const userBalance = walletData.balance
        // console.log(userBalance)

        const userBalance = await API.settings.getWalletUSDTbalance(walletAddress.toString())
        const parsedusdtbalance = userBalance?.jetton_wallets[0]?.balance

        if (!parsedusdtbalance || parsedusdtbalance < amount * 1000000) {
          return setErrorMessage(
            'The linked TON wallet associated with your account does not have sufficient funds to complete the transaction. Please top up your wallet before proceeding.'
          )
        }

        const res = await jettonWallet.sendTransfer(sender, {
          fwdAmount: 1n,
          comment: orderId,
          jettonAmount: Number(amount * 1000000), // пересчет случайной суммы в юниты USDT
          toAddress: INVOICE_WALLET_ADDRESS,
          value: JETTON_TRANSFER_GAS_FEES,
        })
        console.log(res)

        // navigate('/transaction-sent');
        const result = await deposit(amount, walletAddress.toString(), orderId, INVOICE_WALLET_ADDRESS.toString())
        TelegramAPI.ShowAlert(result?.message)
        console.log(`See transaction at https://testnet.tonviewer.com/${usersUsdtAddress.toString()}`)
        handleCloseRef()
        updateUserInfo()
      } catch (error) {
        console.log('Error during transaction:', error)
      }
    },
    [tonClient, walletAddress, sender, navigate]
  )

  const handleConnectWallet = useCallback(() => {
    open()
  }, [open])

  return (
    <Modal ref={modalRef} title="Withdraw" onClose={handleClose} fullScreen={true}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <PageHeader>Deposit</PageHeader>
        </div>
        <BalanceCard />
        <div className={styles.form}>
          <div className={styles.tonConnect}>
            <TonConnectButton />
          </div>

          <TextInput
            label={'Value'}
            value={amount}
            setValue={(e) => setAmount(e.target.value)}
            type="float"></TextInput>
        </div>
        {errorMessage && (
          <div className={styles.depositError}>
            <div className={styles.depositError__title}>Deposit error</div>
            <div className={styles.depositError__message}>{errorMessage}</div>
          </div>
        )}
      </div>
      <div className={styles.modalButtons}>
        <PrimaryButton onClick={handleCloseRef}>
          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.70711 0.792893C9.31658 0.402369 8.68342 0.402369 8.29289 0.792893L0.792893 8.29289C0.402369 8.68342 0.402369 9.31658 0.792893 9.70711L8.29289 17.2071C8.68342 17.5976 9.31658 17.5976 9.70711 17.2071C10.0976 16.8166 10.0976 16.1834 9.70711 15.7929L2.91421 9L9.70711 2.20711C10.0976 1.81658 10.0976 1.18342 9.70711 0.792893Z"
              fill="#000"
            />
          </svg>
        </PrimaryButton>
        <PrimaryButton
          style={amount < 1 ? { color: '#fff', background: 'transparent' } : {}}
          onClick={
            walletAddress ? (amount >= 1 ? () => handleCompletePayment(amount) : () => {}) : handleConnectWallet
          }>
          {walletAddress ? (amount >= 1 ? 'Deposit' : 'Minimum deposit 1$') : 'Connect Wallet'}
        </PrimaryButton>
      </div>
    </Modal>
  )
}
