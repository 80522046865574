import React, { act } from 'react'
import styles from './Navbar.module.css'
import { UserIcon, CardIcon, HornIcon } from '../../assets/icons'
import { useNavigate } from 'react-router-dom'

export default function Navbar({ active = 'profile' }) {
  const tabStyle = (tabname, activeTabName) => {
    if (tabname === activeTabName) {
      return { color: '#fff' }
    }
    return { color: '#a2acb0' }
  }
  const iconPlaceholder = (tabname, activeTabname) => {
    if (tabname === activeTabname) {
      return { background: 'rgba(255, 255, 255, 0.1)', transition: 'all 0.3s ease' }
    }
    return {}
  }

  const navigate = useNavigate()

  const handleNavigate = (path) => {
    navigate(`/${path}`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.nav_item} style={tabStyle('shop', active)} onClick={() => handleNavigate('shop')}>
        <div className={styles.icon_container} style={iconPlaceholder('shop', active)}>
          <CardIcon className={styles.icon} />
        </div>
        <div className={styles.text}>Store</div>
      </div>

      <div className={styles.nav_item} style={tabStyle('profile', active)} onClick={() => handleNavigate('')}>
        <div className={styles.icon_container} style={iconPlaceholder('profile', active)}>
          <UserIcon className={styles.icon} />
        </div>
        <div className={styles.text}>Profile</div>
      </div>

      <div className={styles.nav_item} style={tabStyle('friends', active)} onClick={() => handleNavigate('friends')}>
        <div className={styles.icon_container} style={iconPlaceholder('friends', active)}>
          <HornIcon className={styles.icon} />
        </div>
        <div className={styles.text}>Friends</div>
      </div>
      <div className={styles.nav_item} style={tabStyle('activity', active)} onClick={() => handleNavigate('activity')}>
        <div className={styles.icon_container} style={iconPlaceholder('activity', active)}>
          <svg
            className={styles.icon}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            class="injected-svg"
            role="img"
            color="currentColor">
            <path
              d="M16.75 3.48377C14.9197 2.72623 13 2.9663 12 3.46816V9.99623C13 9.49437 14.9197 9.2543 16.75 10.0118C18.5684 10.7645 20.9494 11.0203 22 11V4.47193C20.9494 4.49227 18.5684 4.23639 16.75 3.48377Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M4 10V7C4 5.89543 4.89543 5 6 5H9L7.5 7"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M20 14L20 17C20 18.1046 19.1046 19 18 19L15 19L16.5 17"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M7.25 13.4838C9.08032 12.7262 11 12.9663 12 13.4682V19.9962C11 19.4944 9.08032 19.2543 7.25 20.0118C5.43158 20.7645 3.05062 21.0203 2 21V14.4719C3.05062 14.4923 5.43158 14.2364 7.25 13.4838Z"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M17 7H17.009"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M7 17H7.00898"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
          </svg>
        </div>
        <div className={styles.text}>Activity</div>
      </div>
    </div>
  )
}
