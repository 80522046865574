import React, { useState, useEffect, useRef } from 'react'
import styles from './PPinModal.module.css'
import Modal from '../../../components/Modal/Modal'
import { deleteIcon } from '../../../assets/icons'
import TelegramAPI from '../../../telegram'
import { useUser } from '../../../contexts/userContext'

export default function PPinModal({ handleClose, type = 'approve', action = null, amount, address }) {
  const [pin, setPin] = useState(['', '', '', ''])
  const [step, setStep] = useState(type === 'create' ? 1 : 2)
  const [confirmPin, setConfirmPin] = useState(['', '', '', ''])
  const [error, setError] = useState(false)
  const pinRef = useRef()

  const { updatePinBack, withdraw } = useUser()

  const buttons = [1, 2, 3, 4, 5, 6, 7, 8, 9, '<', 0, 'delete']

  const handlePinInput = (value) => {
    // Вибрация на каждое нажатие
    TelegramAPI.Vibrate('heavy')

    if (value === 'delete') {
      // Удаление последней введенной цифры
      setPin((prev) => {
        const newPin = [...prev]
        const lastFilledIndex = newPin.indexOf('')
        const indexToDelete = lastFilledIndex === -1 ? 3 : lastFilledIndex - 1
        if (indexToDelete >= 0) {
          newPin[indexToDelete] = ''
        }
        return newPin
      })
      setError(false) // Убираем ошибку при удалении символов
    } else if (value === '<') {
      // Логика для кнопки "Back"
      if (type === 'approve') {
        handleClose()
      } else {
        setError(false)
        setPin(['', '', '', ''])
        setStep(1)
      }
    } else if (typeof value === 'number') {
      // Обрабатываем только числовые значения для PIN-кода
      setPin((prev) => {
        const newPin = [...prev]
        const firstEmptyIndex = newPin.findIndex((item) => item === '')
        if (firstEmptyIndex !== -1) {
          newPin[firstEmptyIndex] = value
        }
        return newPin
      })
    }
  }

  // Проверка после обновления состояния `pin`
  useEffect(() => {
    if (pin.every((digit) => digit !== '')) {
      handleConfirm()
    }
  }, [pin])

  const handleConfirm = async () => {
    if (type === 'create' && step === 1) {
      // Переход на подтверждение
      setConfirmPin([...pin])
      setPin(['', '', '', ''])
      setStep(2)
    } else if (type === 'create' && step === 2) {
      // Подтверждение пароля
      if (JSON.stringify(pin) === JSON.stringify(confirmPin)) {
        setError(false)
        const result = await updatePinBack(pin.join(''))
        TelegramAPI.ShowAlert(result?.message)
        handleClose()
      } else {
        setError(true)
        // setPin(['', '', '', ''])
      }
    } else if (type === 'approve') {
      const result = await withdraw(parseFloat(amount), address, pin.join(''))
      if (result?.errorCode) {
        setError(true)
      } else {
        TelegramAPI.ShowAlert(result.message)
        handleClose()
      }
    }
  }

  return (
    <Modal ref={pinRef} title="Withdraw" onClose={handleClose} fullScreen={true}>
      <div className={styles.header}>
        <div className={styles.title}>
          {type === 'create' && step === 1 ? 'Create Payment Password' : 'Confirm Payment Password'}
        </div>
        <div className={styles.description}>
          {type === 'create' && step === 1
            ? 'Create a payment password to protect your balance'
            : 'Confirm the payment password to proceed'}
        </div>
        <div className={styles.dots}>
          {pin.map((item, index) => (
            <div
              key={index}
              className={`${styles.dot} ${item ? styles.active : ''} ${error ? styles.error : ''}`}></div>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        {buttons.map((item, index) => (
          <button
            key={index}
            className={styles.button}
            onClick={() => handlePinInput(item)}
            style={index === 9 || index === 11 ? { border: 'none' } : {}}>
            {item === 'delete' ? deleteIcon() : item}
          </button>
        ))}
      </div>
    </Modal>
  )
}
